









import Vue from 'vue';
import Scaffold from '@/components/Scaffold.vue';
import TourForm from '@/tour/TourForm.vue';
import EditorMode from '@/common/editor.mode';

export default Vue.extend({
  components: { TourForm, Scaffold },
  data() {
    return {
      duplicate: EditorMode.duplicate,
    };
  },
});
